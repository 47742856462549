import { lazy } from "react";

import AiWorkersPage from "pages/AIWorkers/Preview";
import ErrorPage from "pages/Error";
import { ActiveTerritoryScreen } from "pages/Territory";

export interface RouteI {
  path: string;
  element: JSX.Element;
  isProtected: boolean;
}

const Integrations = lazy(() => import("../pages/Settings/Integrations").then((module) => ({ default: module.default })));
// const Details = lazy(() => import("../pages/Settings/Integrations/Details").then((module) => ({ default: module.default })));
const Settings = lazy(() => import("../pages/Settings").then((module) => ({ default: module.default })));
const AboutUs = lazy(() => import("../pages").then((module) => ({ default: module.AboutUs })));
const Legal = lazy(() => import("../pages").then((module) => ({ default: module.Legal })));
const Login = lazy(() => import("../pages").then((module) => ({ default: module.Login })));
const Onboarding = lazy(() => import("../pages").then((module) => ({ default: module.Onboarding })));
const NotFoundPage = lazy(() => import("../pages").then((module) => ({ default: module.NotFoundPage })));
const InvalidCredentials = lazy(() => import("../pages").then((module) => ({ default: module.InvalidCredentials })));

const Home = lazy(() => import("../pages/Home"));
const PeopleContactsPage = lazy(() => import("../pages/Contacts"));
const RevicChatBot = lazy(() => import("../pages/Revic"));

const WhitespaceAndTerritory = lazy(() => import("../pages/Territory").then((module) => ({
  default: module.default,
})));

const Plan = lazy(() => import("../pages/Plan").then((module) => ({ default: module.Plan })));

export const Routes: RouteI[] = [
  {
    path: "/",
    element: <Home />,
    isProtected: true,
  },
  {
    path: "/territory",
    element: (
      <WhitespaceAndTerritory targetContent={ActiveTerritoryScreen.Territory} />
    ),
    isProtected: true,
  },
  {
    path: "/whitespace",
    element: (
      <WhitespaceAndTerritory
        targetContent={ActiveTerritoryScreen.Whitespace}
      />
    ),
    isProtected: true,
  },
  {
    path: "/plan-wizard",
    element: <Plan />,
    isProtected: true,
  },
  {
    path: "/contacts",
    element: <PeopleContactsPage />,
    isProtected: true,
  },
  {
    path: "/settings/*",
    element: <Settings />,
    isProtected: true,
  },
  {
    path: "/aiworkers/*",
    element: <AiWorkersPage />,
    isProtected: true,
  },
  {
    path: "/revic",
    element: <RevicChatBot />,
    isProtected: true,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
    isProtected: true,
  },
  {
    path: "/pending",
    element: <InvalidCredentials />,
    isProtected: false,
  },
  {
    path: "/about",
    element: <AboutUs />,
    isProtected: false,
  },
  {
    path: "/legal",
    element: <Legal />,
    isProtected: false,
  },
  {
    path: "/login",
    element: <Login />,
    isProtected: false,
  },
  {
    path: "/error",
    element: <ErrorPage />,
    isProtected: false,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
    isProtected: false,
  },
];
