import { SWEmployees, SWIndustry, SWRevenue } from "./aiWorkers";

export enum UserPermissions {
  read = "read",
  write = "write",
  execute = "execute",
}

// @TODO:Ali is this needed?
export type KeyofUserPerms = keyof typeof UserPermissions;

export type UserScope = {
  users: KeyofUserPerms[]; // can add/modify users
  connectOrganization: KeyofUserPerms[]; // providers
  similarWinsBot: KeyofUserPerms[];
  accountResearchBot: KeyofUserPerms[];
  personFinderBot: KeyofUserPerms[];
  chat: KeyofUserPerms[];
  territory: KeyofUserPerms[]; // can specify territories
  onboarding: {
    onboarding: KeyofUserPerms[];
  };
};

export type UserLicense = {
  totalLicenses: number;
  remainingLicenses: number;
  users: { name: string; email: string }[];
};


export enum WorkerConfigurationStatus {
  Idle = 'Idle',
  FineTuning = 'FineTuning',
  Active = 'Active',
}

enum AccountRankFilterType {
  EXCLUDE = 'exclude',
  INCLUDE = 'include'
}

export interface AccountRankConfig {
  filters: {
    accountType: string[];
    opportunityType: string[];
    dealPeriod: {
      min: string;
      max: string;
    };
    dealSize: {
      min: number;
      max: number;
    };
    companySize: {
      min: number;
      max: number;
    };
  };
  status?: WorkerConfigurationStatus;
  filterType?: AccountRankFilterType;
}

export interface Settings {
  id: string;
  instanceURL: string;
  SFuserId: string;
  SFinstanceId: string;
  activities: {
    excludedAccounts: any[];
    excludedTypes: any[];
    dismissedActivities: any[];
    [key: string]: any;
  };
  wizards?: Record<string, any>; // accommodates `wizards` object in settings payload
  employeeHierarchy?: {
    id: string;
    name: string;
    count: number;
    subordinates: any[];
  };
  workersConfig?: {
    accountRank?: AccountRankConfig;
  };
  scope?: UserScope & {
    settings?: {
      users: string[];
      connectOrganization: string[];
    };
    revic?: {
      chat: string[];
    };
    onboarding?: Record<string, any>;
  };
  isAdmin?: boolean;
  isTrial?: boolean;
  isFree?: boolean;
  [key: string]: any;
}

export interface OnboardingData {
  locations?: string[];
  industries?: string[];
  revenue?: {
    min?: number;
    max?: number;
  };
  employees?: {
    min?: number;
    max?: number;
  };
  accounts?: string[];
  domains?: string[];
}

export type AccountDomain = {
  name: string;
  domain: string;
};

export type SimilarWins = {
  name: string;
  description: string;
  website: string;
  industry: string;
  annualRevenue: number;
  numberOfEmployees: number;
};

export type Statistics = {
  employees: SWEmployees;
  revenue: SWRevenue;
  industry: SWIndustry[];
};

export type SimilarAccounts = {
  name: string;
  accounts: SimilarWins[];
};

export type SimilarWinsT = {
  results: SimilarAccounts[];
  statistics: Statistics;
};

export type SimilarWinsResponse = {
  success: boolean;
  response: SimilarWinsT;
};
