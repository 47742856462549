import type {
  AccountDomain,
  OnboardingData,
  Settings,
  SimilarWinsResponse,
  SimilarWinsT,
  UserLicense,
} from "types/api";
import { AxiosNode } from "utils/axios";

export type OnboardingOptions = {
  industry: string[];
  location: string[];
};

export type VerifiedAccounts = {
  accepted: string[];
  rejected: string[];
  message?: string;
};

class SettingsAPI {
  static get = async (): Promise<Settings> => {
    const { data } = await AxiosNode.get<{ settings: Settings }>(
      "/user/settings",
      {
        retryEnabled: true,
      }
    );
    const {
      id,
      SFuserId,
      SFinstanceId,
      activities,
      wizards,
      instanceURL,
      scope,
      admin,
      free,
      trial,
      settings: { workersConfig },
      employeeHierarchy
    } = data.settings;

    return {
      id,
      SFuserId,
      SFinstanceId,
      activities,
      wizards,
      instanceURL,
      employeeHierarchy,
      scope,
      workersConfig,
      isAdmin: admin,
      isFree: free,
      isTrial: trial
    };
  };

  static update = async (settings: any) => {
    const {
      data: { data },
    } = await AxiosNode.patch("user/settings", settings);
    return data;
  };

  static getLicense = async (): Promise<UserLicense> => {
    try {
      const { data } = await AxiosNode.get<UserLicense>("/license");
      return data;
    } catch (error) {
      return {} as UserLicense;
    }
  };

  static getOnboardingOptions = async () => {
    try {
      const { data } = await AxiosNode.get<OnboardingOptions>(
        "/territory/options"
      );
      return data;
    } catch (error) {
      return {
        industry: [],
        location: [],
      };
    }
  };

  static searchAccounts = async (search: string) => {
    try {
      const { data } = await AxiosNode.get<AccountDomain[]>(
        "/accounts/search",
        {
          retryEnabled: true,
          params: {
            search,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  };

  static refineTerritory = async (territory: OnboardingData) => {
    try {
      const { data } = await AxiosNode.post("/user/territory", territory, {
        retryEnabled: true,
      });
      return { success: true, response: data };
    } catch (error) {
      return { success: false, response: error };
    }
  };

  static similarWins = async (
    accounts: string[]
  ): Promise<SimilarWinsResponse> => {
    try {
      const { data } = await AxiosNode.post<SimilarWinsT>(
        "/bot/similarWins",
        {
          accounts,
        },
        {
          retryEnabled: true,
        }
      );
      return { success: true, response: data };
    } catch (error) {
      return {
        success: false,
        response: {
          results: [],
          statistics: {
            employees: {
              min: 0,
              max: 0,
              mean: 0,
            },
            revenue: {
              min: 0,
              max: 0,
              mean: 0,
            },
            industry: [],
          },
        },
      };
    }
  };

  static finishOnboarding = async () => {
    try {
      const { data } = await AxiosNode.post(
        "/user/onboarding",
        {},
        { retryEnabled: true }
      );
      return { success: true, response: data };
    } catch (error) {
      return { success: false, response: error };
    }
  };

  static verifyAccounts = async (
    accounts: string[]
  ): Promise<{
    success: boolean;
    response: VerifiedAccounts;
  }> => {
    try {
      const { data } = await AxiosNode.post<VerifiedAccounts>(
        "/accounts/verify",
        { accounts },
        { retryEnabled: true }
      );
      return { success: true, response: data };
    } catch (error) {
      return {
        success: false,
        response: {
          accepted: [],
          rejected: [],
          message: "Error verifying accounts",
        },
      };
    }
  };
}

export default SettingsAPI;
